import { Account } from 'accorto';
import { TenantUser } from 'accorto';
import { ActivityType } from 'accorto';
import { Resource } from 'accorto';
import { DataRecordI } from 'accorto';
import { DataRecordF } from 'accorto';
import { ProjectLine } from 'accorto';
import { Contact } from 'accorto';
import { Project } from 'accorto';
import { ProjectPhase } from 'accorto';
import { Tenant } from 'accorto';
import { TEItemI, TEItemD } from './t-e-item-i';
import { FinancialAccount } from './financial-account';
import { DataRecord } from 'accorto';

/* GenTsClass domain: TEItem */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TEItem
 */
export class TEItem {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * label: Status
   */
  public status?: string; // optional=true

  /**
   * label: Billable
   */
  public invoiced?: string; // optional=true

  /**
   * 90 (min) - 1:30 (hours:minutes) - 1h30m = 1.5 (decimal)
   * help: Converted to decimal hours
   * label: Hours
   */
  public hours?: number; // optional=true

  /**
   * Minutes
   * label: Minutes
   */
  public minutes?: number; // optional=true

  /**
   * label: Start Time
   */
  public timeStart?: number; // optional=true

  /**
   * label: End Time
   */
  public timeEnd?: number; // optional=true

  /**
   * label: Date
   */
  public teDate?: number; // optional=true

  /**
   * label: Receipt Amount/Quantity
   */
  public tempAQ?: number; // optional=true

  /**
   * label: Receipt Date
   */
  public receiptDate?: number; // optional=true

  /**
   * label: Receipt Amount
   */
  public receiptAmount?: number; // optional=true

  /**
   * label: Receipt Quantity
   */
  public receiptQuantity?: number; // optional=true

  /**
   * label: Currency
   */
  public currencyCode?: string; // optional=true

  /**
   * label: Expense Amount
   */
  public expenseAmount?: number; // optional=true

  /**
   * fk FinancialAccount
   * label: Fin Account
   */
  public financialAccount?: FinancialAccount; // optional=true

  public financialAccountId?: number; // optional=true

  /**
   * label: Import Source
   */
  public sourceImport?: string; // optional=true

  /**
   * label: Fin Account
   */
  public financialAccountSfId?: string; // optional=true

  /**
   * fk Project
   * label: Project
   */
  public project?: Project; // optional=true

  public projectId?: number; // optional=true

  /**
   * label: Project
   */
  public projectSfId?: string; // optional=true

  /**
   * fk ProjectPhase
   * label: Project Phase
   */
  public projectPhase?: ProjectPhase; // optional=true

  public projectPhaseId?: number; // optional=true

  /**
   * label: Project Phase
   */
  public projectPhaseSfId?: string; // optional=true

  /**
   * fk ActivityType
   * label: Activity Type
   */
  public activityType?: ActivityType; // optional=true

  public activityTypeId?: number; // optional=true

  /**
   * fk ProjectLine
   * label: Project Line
   */
  public projectLine?: ProjectLine; // optional=true

  public projectLineId?: number; // optional=true

  /**
   * label: Activity Type
   */
  public activityTypeSfId?: string; // optional=true

  /**
   * label: Project Line
   */
  public projectLineSfId?: string; // optional=true

  /**
   * fk Account
   * label: Account
   */
  public account?: Account; // optional=true

  public accountId?: number; // optional=true

  /**
   * label: Account
   */
  public accountSfId?: string; // optional=true

  /**
   * fk Contact
   * label: Contact
   */
  public contact?: Contact; // optional=true

  public contactId?: number; // optional=true

  /**
   * label: Contact
   */
  public contactSfId?: string; // optional=true

  /**
   * fk Resource
   * label: Resource
   */
  public resource?: Resource; // optional=true

  public resourceId?: number; // optional=true

  /**
   * label: Resource
   */
  public resourceSfId?: string; // optional=true

  /**
   * label: Sun
   */
  public x1?: number; // optional=true

  /**
   * label: Sun
   */
  public d1?: string; // optional=true

  /**
   * label: Mon
   */
  public x2?: number; // optional=true

  /**
   * label: Mon
   */
  public d2?: string; // optional=true

  /**
   * label: Tue
   */
  public x3?: number; // optional=true

  /**
   * label: Tue
   */
  public d3?: string; // optional=true

  /**
   * label: Wed
   */
  public x4?: number; // optional=true

  /**
   * label: Wed
   */
  public d4?: string; // optional=true

  /**
   * label: Thu
   */
  public x5?: number; // optional=true

  /**
   * label: Thu
   */
  public d5?: string; // optional=true

  /**
   * label: Fri
   */
  public x6?: number; // optional=true

  /**
   * label: Fri
   */
  public d6?: string; // optional=true

  /**
   * label: Sat
   */
  public x7?: number; // optional=true

  /**
   * label: Sat
   */
  public d7?: string; // optional=true

  /**
   * Total hours
   * label: Sum
   */
  public x0?: number; // optional=true

  public latitude?: number; // optional=true

  public longitude?: number; // optional=true

  public costRate?: number; // optional=true

  public billingRate?: number; // optional=true


  /**
   * @return TEItem record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'TEItem';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): TEItem {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, TEItemD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, TEItemD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: TEItemI): TEItem {
    Object.keys(TEItemD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'financialAccount') { // fk
          this.financialAccount = new FinancialAccount();
          this.financialAccount.setFromI(value);
        } else if (key === 'project') { // fk
          this.project = new Project();
          this.project.setFromI(value);
        } else if (key === 'projectPhase') { // fk
          this.projectPhase = new ProjectPhase();
          this.projectPhase.setFromI(value);
        } else if (key === 'activityType') { // fk
          this.activityType = new ActivityType();
          this.activityType.setFromI(value);
        } else if (key === 'projectLine') { // fk
          this.projectLine = new ProjectLine();
          this.projectLine.setFromI(value);
        } else if (key === 'account') { // fk
          this.account = new Account();
          this.account.setFromI(value);
        } else if (key === 'contact') { // fk
          this.contact = new Contact();
          this.contact.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // TEItem
