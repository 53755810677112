import { TenantUserI } from 'accorto';
import { TenantI } from 'accorto';

/* GenTsInterface domain: FinancialAccount */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: FinancialAccount
 */
export interface FinancialAccountI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * Account Code
   * label: Code
   */
  code?: string;

  /**
   * Use in Expense Reports
   * label: T&E Report
   */
  isExpenseReport?: boolean;

  fixedAmount2?: number;

  isPerDiem?: boolean;

  /**
   * Per Diem Type (calculated)
   * help: e.g. gsa | gsa:meals | gsa:lodging
   * label: Per Diem Type
   */
  perDiemType?: string;

} // FinancialAccount

/** FinancialAccount Definition */
export const FinancialAccountD = {
  recordTypeName: 'FinancialAccount',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  code: {
    n: 'code',
    t: 'string'
  },
  isExpenseReport: {
    n: 'isExpenseReport',
    t: 'boolean',
    d: true
  },
  fixedAmount2: {
    n: 'fixedAmount2',
    t: 'number'
  },
  isPerDiem: {
    n: 'isPerDiem',
    t: 'boolean'
  },
  perDiemType: {
    n: 'perDiemType',
    t: 'string'
  },
}; // FinancialAccount
