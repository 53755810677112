import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 't4d-time-keeper',
  templateUrl: './time-keeper.component.html',
  styleUrls: ['./time-keeper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeKeeperComponent {

  message?: string;
  error?: string;

  busy: boolean = true;

  constructor() {
  }

}
