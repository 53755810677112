import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {
  AccortoService,
  DataRecord,
  DataTable,
  DataType,
  FormManager,
  Logger,
  ModelUtil,
  UiFormField,
  UiFormSection,
  UiTab
} from 'accorto';
import {TrackItemService} from '../track-item/track-item.service';
import {CResponseTrack} from '../model/c-response-track';
import {PerDiemItem} from '../model/per-diem-item';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TEItemUtil} from '../model/t-e-item-util';

/**
 * GSA Per Diem
 */
@Component({
  selector: 't4d-per-diem',
  templateUrl: './per-diem.component.html',
  styleUrls: ['./per-diem.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PerDiemComponent implements OnInit, OnDestroy {

  message?: string;
  error?: string;
  busy: boolean = false;

  // Query
  tripFm: FormManager;
  tripStartDate: UiFormField;
  tripEndDate: UiFormField;
  tripDestination: UiFormField;
  tripRecord: DataRecord = new DataRecord();

  // Result
  items: PerDiemItem[] = [];
  diemForm: FormGroup;
  diemDays: FormArray;

  totals: number = 0;
  totalMeals: number = 0;
  totalBreakfast: number = 0;
  totalLunch: number = 0;
  totalDinner: number = 0;
  totalIncidentals: number = 0;
  totalLodging: number = 0;

  ui: UiTab = new UiTab();
  record: DataRecord = new DataRecord();

  private log: Logger = new Logger('PerDiem');
  private subscriptions: Subscription[] = [];

  /**
   * https://www.gsa.gov/travel/plan-book/per-diem-rates/per-diem-rates-lookup/
   * ?action=perdiems_report&state=&fiscal_year=2019&zip=94061&city=
   */
  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private conf: AccortoService,
              private service: TrackItemService) {
    // Query Form
    const table = new DataTable();
    table.name = 'trip';
    const ui = new UiTab();
    ui.name = table.name;
    ui.dataTable = table;
    const formSection = new UiFormSection();
    ui.formSectionList.push(formSection);

    ModelUtil.newDataColumn(table, 'startDate', DataType.DATE, 'Start Date', true);
    ModelUtil.newDataColumn(table, 'endDate', DataType.DATE, 'End Date', true);
    const d = ModelUtil.newDataColumn(table, 'destination', DataType.STRING, 'Destination', true);
    d.autocomplete = 'off';

    this.tripStartDate = ModelUtil.cloneUiFormField(table, 'startDate');
    formSection.uiFormFieldList.push(this.tripStartDate);
    this.tripEndDate = ModelUtil.cloneUiFormField(table, 'endDate');
    formSection.uiFormFieldList.push(this.tripEndDate);
    this.tripDestination = ModelUtil.cloneUiFormField(table, 'destination');
    this.tripDestination.description = 'Zip code';
    formSection.uiFormFieldList.push(this.tripDestination);
    //
    this.tripRecord.rowNo = 0;
    this.tripFm = new FormManager(fb, ui, this.tripRecord, undefined,
      conf, undefined, undefined, undefined, undefined);

    // Per Diem Form
    this.diemForm = this.fb.group({
      days: this.fb.array([])
    });
    this.diemDays = this.diemForm.get('days') as FormArray;
    this.diemForm.valueChanges.subscribe((v) => {
      this.log.debug('diemForm', v)();
      this.calculate(v);
    });
  } // constructor

  isDebug(): boolean {
    return this.conf.isDebug;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // ui
    this.subscriptions.push(this.route.data.subscribe((data => {
      if (data.uiTab) {
        this.log.debug('uiTab', data.uiTab)();
        this.ui = TEItemUtil.cloneUi(data.uiTab, 'ref',
          true, this.conf.session?.settings);
        this.record = ModelUtil.newDataRecord(this.ui);
      }
    })));
  } // ngOnInit

  /**
   * Create Per Diems
   */
  onDiemSubmit(): void {
    this.log.info('onDiemSubmit', this.items, this.record)();
    this.message = 'creating ...';
    this.error = undefined;
    this.busy = true;
    this.service.perDiemExpenses(this.items, this.record).subscribe((response: CResponseTrack) => {
      this.message = response.message;
      this.error = response.error;
      this.busy = false;
      if (!this.error) {
        const pp: Params = {
          startTimeMs: response.startTimeMs,
          endTimeMs: response.endTimeMs
        };
        this.router.navigate([ '/expense-table' ],
          { queryParams: pp });
      }
    });
  } // onDiemSubmit

  onNew(): void {
    this.log.info('onNew')();
    this.tripFm.formGroup.reset();
    this.items = [];
  }

  /**
   * Submit Query
   */
  onTripSubmit(): void {
    this.error = undefined;
    this.message = 'querying ...';
    this.busy = true;

    const item = new PerDiemItem();
    item.type = 'gsa';
    const dest = this.tripRecord.changeMap.destination;
    item.destination = dest ?? undefined;
    item.dateMs = Number(this.tripRecord.changeMap.startDate);
    item.endDateMs = Number(this.tripRecord.changeMap.endDate);
    this.log.info('onTripSubmit', item)();
    this.service.queryPerDiem(item)
      .subscribe((result: CResponseTrack) => {
        this.error = result.error;
        this.message = result.message;
        this.items = result.perDiemItems;
        this.buildForm();
        this.busy = false;
      });
  } // onTripSubmit

  /**
   * Build Per Diem Form
   */
  private buildForm(): void {
    this.diemDays.clear();
    this.items.forEach((item: PerDiemItem) => {
      item.isMeals = true;
      item.isMeals2 = false;
      item.isBreakfast = false;
      item.isLunch = false;
      item.isDinner = false;
      item.isIncidental = false;
      item.isLodging = true;
      this.diemDays.push(this.buildFormDay(item));
    });
  } // buildForm

  /**
   * build per diem day
   */
  private buildFormDay(item: PerDiemItem): FormGroup {
    return this.fb.group({
      meals: [ item.isMeals ],
      meals2: [ item.isMeals2 ],
      breakfast: [ item.isBreakfast ],
      lunch: [ item.isLunch ],
      dinner: [ item.isDinner ],
      incidental: [ item.isIncidental ],
      lodging: [ item.isLodging ],
      description: [ '' ]
    });
  } // buildFormDay

  /**
   * Calculate total(s)
   */
  private calculate(v: any): void {
    this.totalMeals = 0;
    this.totalBreakfast = 0;
    this.totalLunch = 0;
    this.totalDinner = 0;
    this.totalIncidentals = 0;
    this.totalLodging = 0;

    for (let i = 0; i < this.diemDays.length; i++) {
      const item: PerDiemItem = this.items[i];
      const group = this.diemDays.at(i) as FormGroup;
      const values = group.value;
      // this.log.debug('calculate ' + i, item, values)();

      // toggle meals - details
      if (values.meals && !item.isMeals) { // meals changed
        if (values.breakfast || values.lunch || values.dinner || values.incidental) {
          // toggle individual items off as there is a daily rate
          group.patchValue({
            breakfast: false,
            lunch: false,
            dinner: false,
            incidental: false
          }, { emitEvent: false });
          values.breakfast = false;
          values.lunch = false;
          values.dinner = false;
          values.incidental = false;
        }
      } else if (item.isMeals
        && (values.breakfast || values.lunch || values.dinner || values.incidental)) {
        if (values.meals) { // toggle meals off as there is a single item
          group.patchValue({
            meals: false
          }, { emitEvent: false });
          values.meals = false;
        }
      }

      // calculate
      item.isMeals = values.meals;
      if (item.isMeals) {
        this.totalMeals += (item.rateMeals ? item.rateMeals : 0);
      }
      item.isBreakfast = values.breakfast;
      if (item.isBreakfast) {
        this.totalBreakfast += (item.rateBreakfast ? item.rateBreakfast : 0);
      }
      item.isLunch = values.lunch;
      if (item.isLunch) {
        this.totalLunch += (item.rateLunch ? item.rateLunch : 0);
      }
      item.isDinner = values.dinner;
      if (item.isDinner) {
        this.totalDinner += (item.rateDinner ? item.rateDinner : 0);
      }
      item.isIncidental = values.incidental;
      if (item.isIncidental) {
        this.totalIncidentals += (item.rateIncidental ? item.rateIncidental : 0);
      }
      item.isLodging = values.lodging;
      if (item.isLodging) {
        this.totalLodging += (item.rateLodging ? item.rateLodging : 0);
      }
      item.description = values.description;
    }
    this.totals = this.totalMeals + this.totalBreakfast + this.totalLunch + this.totalDinner
      + this.totalIncidentals + this.totalLodging;
  } // doCalculate


} // PerDiemComponent
