import { PerDiemItem } from './per-diem-item';
import { CRequest } from 'accorto';
import { DataRecord } from 'accorto';

/* GenTsClass client: com.track4d.domain.CRequestTrack */
/* tslint:disable max-line-length no-inferrable-types */

export enum Track4dType {
  CHECKIN = 'CHECKIN',
  DELETE = 'DELETE',
  EXPENSES = 'EXPENSES',
  PERDIEM = 'PERDIEM',
  QUERY = 'QUERY',
  SAVE = 'SAVE',
  STAT = 'STAT',
  SUBMIT = 'SUBMIT'
}

/**
 * extends: CRequest
 * name: CRequestTrack
 */
export class CRequestTrack extends CRequest {

  /**
   * Expenses until
   * label: End Time ms
   */
  public endTimeMs?: number; // optional=null

  /**
   * Status = New,Completed,NotApproved
   * label: editable only
   */
  public isEditable?: boolean; // optional=null

  /**
   * ColumnName ASC|DESC NULLS FIRST|LAST
   * label: Order By
   */
  public orderBys: string[] = [];

  /**
   * label: Per Diem Request Info
   */
  public perDiemItems: PerDiemItem[] = [];

  /**
   * label: Query only Id
   */
  public queryId?: string; // optional=null

  /**
   * label: Records to save/delete
   */
  public records: DataRecord[] = [];

  /**
   * Converted to week start - 0 for current
   * label: Start Time ms
   */
  public startTimeMs?: number; // optional=null

  /**
   * label: Request Type
   */
  public type?: Track4dType; // optional=null

  /**
   * e.g. after Save/Delete
   * label: ReQuery Type
   */
  public typeReQuery?: Track4dType; // optional=null

} // CRequestTrack
