import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';

import * as d3 from 'd3';
import {BarChartStackedData} from './bar-chart-stacked.data';
import {Logger} from 'accorto';

/**
 * Stacked Bar Chart based on time
 * https://github.com/d3/d3-shape/blob/master/README.md#stacks
 */
@Component({
  selector: 't4d-bar-chart-stacked',
  templateUrl: './bar-chart-stacked.component.html',
  styleUrls: ['./bar-chart-stacked.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BarChartStackedComponent implements OnChanges {

  /** The Chart Data */
  @Input() cd: BarChartStackedData = new BarChartStackedData();
  /** Svg Width */
  @Input() width: number = 600;
  /** Svg Height */
  @Input() height: number = 300;

  /** div to contain svg */
  @ViewChild('chart', {static: true}) chartContainer?: ElementRef;
  /** div to contain svg */
  @ViewChild('checkbox', {static: true}) checkbox?: ElementRef;

  isNoData: boolean = true;

  private margin = {top: 20, right: 20, bottom: 30, left: 40};

  private contentWidth: number = 0;
  private contentHeight: number = 0;

  private svg: d3.Selection<any, any, any, any> = d3.selection();
  private g: d3.Selection<any, any, any, any> = d3.selection();
  private xScale: d3.ScaleBand<string> = d3.scaleBand();
  private yScale: d3.ScaleLinear<number, number> = d3.scaleLinear();
  private zScale: d3.ScaleOrdinal<string, string> = d3.scaleOrdinal(d3.schemeCategory10);

  private y0: d3.ScaleBand<string> = d3.scaleBand();
  private y1: d3.ScaleLinear<number, number> = d3.scaleLinear();

  private dataByGroup?: any;

  /** Logger */
  private log: Logger = new Logger('BarChartStacked');

  constructor() {
    /* Test Data
    this.cd.subLabel = 'Example';
    this.cd.dimensionLabelPrefix = '$';
    this.cd.isNoData = false;

    this.cd.add(new Date(2017, 1, 10), 'c1', 1);
    this.cd.add(new Date(2017, 2, 10), 'c1', 2);
    this.cd.add(new Date(2017, 3, 10), 'c1', 3);
    this.cd.add(new Date(2017, 1, 10), 'c1', 10);

    this.cd.add(new Date(2017, 1, 10), 'c2', 11);
    this.cd.add(new Date(2017, 2, 10), 'c2', 12);
    this.cd.add(new Date(2017, 3, 10), 'c2', 13);

    this.cd.add(new Date(2017, 1, 10), 'c3', 5);
    this.cd.add(new Date(2017, 2, 10), 'c3', 7);
    this.cd.add(new Date(2017, 3, 10), 'c3', 7);
    /* */
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.log.info('ngOnChanges', this.cd)();
    this.isNoData = this.cd.data.length === 0;
    // this.initSvg();
    // this.initScale();
    // this.update();
  }

  //  https://jsfiddle.net/levvsha/vrbq7ebz/

  // https://github.com/d3/d3-shape/blob/master/README.md#stacks

  // https://bl.ocks.org/mjfoster83/7c9bdfd714ab2f2e39dd5c09057a55a0
  // https://bl.ocks.org/DimsumPanda/689368252f55179e12185e13c5ed1fee
  // http://www.adeveloperdiary.com/d3-js/create-stacked-bar-chart-using-d3-js/

  /**
   * Layout Change
   */
  onLayoutChange(evt: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = this.checkbox!.nativeElement;
    // this.log.log('onLayoutChange', element.checked)();
    if (element.checked) {
      // this.transitionStacked();
    } else {
      // this.transitionLayered();
    }
  } // onLayoutChange

  /* eslint-disable-next-line , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , , ,  */
} // BarChartStackedComponent
