
/* GenTsClass client: com.track4d.domain.PerDiemItem */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: PerDiemItem
 */
export class PerDiemItem {

  /**
   * label: The Date
   */
  public dateMs?: number; // optional=null

  /**
   * label: Per Diem Description
   */
  public description?: string; // optional=null

  /**
   * label: Destination
   */
  public destination?: string; // optional=null

  /**
   * label: Destination Detail
   */
  public destinationDetail?: string; // optional=null

  /**
   * label: Per Diem Destination options
   */
  public destinationOptions: string[] = [];

  /**
   * label: The End Date (for requests)
   */
  public endDateMs?: number; // optional=null

  public isBreakfast?: boolean; // optional=null

  public isDinner?: boolean; // optional=null

  public isIncidental?: boolean; // optional=null

  public isLodging?: boolean; // optional=null

  public isLunch?: boolean; // optional=null

  /**
   * label: Full Rate Meals
   */
  public isMeals?: boolean; // optional=null

  /**
   * label: Reduced Rate Meals
   */
  public isMeals2?: boolean; // optional=null

  /**
   * label: Rate for Breakfast or Reduction
   */
  public rateBreakfast?: number; // optional=null

  /**
   * label: Rate for Dinner or Reduction
   */
  public rateDinner?: number; // optional=null

  public rateIncidental?: number; // optional=null

  /**
   * label: Lodging Rate
   */
  public rateLodging?: number; // optional=null

  /**
   * label: Rate for Lunch or Reduction
   */
  public rateLunch?: number; // optional=null

  /**
   * label: Meals and Incidentals
   */
  public rateMeals?: number; // optional=null

  /**
   * label: Meals reduced
   */
  public rateMeals2?: number; // optional=null

  /**
   * label: record type: gsa, de, ...
   */
  public type?: string; // optional=null

} // PerDiemItem
