import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {PieChartArc, PieChartData} from './pie-chart.data';
import {Logger} from 'accorto';

import * as d3 from 'd3';

/**
 * Pie Chart
 */
@Component({
  selector: 't4d-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent implements OnChanges {

  /** Chart Data */
  @Input() cd: PieChartData = new PieChartData();

  /** Svg Width */
  @Input() width: number = 300;
  /** Svg Height */
  @Input() height: number = 300;

  @ViewChild('chart', {static: true}) chartContainer?: ElementRef;

  isNoData: boolean = false;

  private margin = {top: 40, right: 20, bottom: 30, left: 40};
  private contentWidth: number = 0;
  private contentHeight: number = 0;

  private svg: d3.Selection<any, any, any, any> = d3.selection();
  private g: d3.Selection<any, any, any, any> = d3.selection();
  private pieContainer: any; // d3.Selection<any, any, any, any>;
  /** Logger */
  private log: Logger = new Logger('PieChart');

  constructor() {
    // test data
    this.cd.subLabel = 'Example';
    this.cd.dimensionLabelPrefix = '$';
    this.cd.add('a', 10);
    this.cd.add('b', 20);
    this.cd.add('c', 30);

    const map = new Map<string, string>();
    map.set('a', 'aa');
    map.set('b', 'bb');
    map.set('c', 'cc');
    this.cd.process(map);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.log.info('ngOnChanges', this.cd)();
    this.isNoData = this.cd.data.length === 0;
    this.initSvg();
    this.updatePie();
  } // ngOnChanges

  /**
   * Arc Interpolator
   * @param el PieChartComponent
   * @param d data {data: pieChartArc, index, value, startAngle, endAngle}
   */
  // eslint-disable-next-line
  private getArcInterpolator(el: any, d: any) {
    const oldValue = el._oldValue;
    const interpolator = d3.interpolate({
      startAngle: oldValue ? oldValue.startAngle : 0,
      endAngle: oldValue ? oldValue.endAngle : 0
    }, d);
    // get the start value of the interpolator and bind that.
    // so we can use it for the next interpolator.
    el._oldValue = interpolator(0);
    return interpolator;
  } // getArcInterpolator


  private initSvg(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = this.chartContainer!.nativeElement;
    d3.select(element).select('svg').remove();
    this.svg = d3.select(element).append('svg')
      .attr('width', this.width)
      .attr('height', this.height);
    // console.log('initSvg', this.svg);

    this.contentWidth = this.width - this.margin.left - this.margin.right;
    this.contentHeight = this.height - this.margin.top - this.margin.bottom;
    this.g = this.svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    this.pieContainer = this.g.append('g')
      .attr('transform', 'translate(' + this.contentWidth / 2 + ',' + this.contentHeight / 2 + ')')
      .attr('class', 'pie-container');
  } // initSvg

  /**
   * Update Arc Elements
   * @param arcs pie data
   * @param arc base arc dimensions
   * @param arcPopup popup arc dimensions
   */
  private updateArcElements(arcs: d3.PieArcDatum<{ valueOf(): number; }>[],
                            arc: d3.Arc<any, d3.DefaultArcObject>,
                            arcPopup: d3.Arc<any, d3.DefaultArcObject>): void {
    const arcElements = this.pieContainer
      .selectAll('.arc')
      .data(arcs);
    arcElements.enter()
      .append('path')
      .attr('class', 'arc')
      .style('fill', (d: any, i: any) => { // color
        return d3.interpolatePlasma(i / this.cd.data.length);
      })

      .merge(arcElements)
      /**/
      .on('mouseover', (d: any) => {
        // d3.select(this.pieContainer).attr('d', (dd) => {
        //  return arcPopup(dd);
        // });
        const centerText = this.pieContainer
          .selectAll('.center')
          .data([d]);
        centerText.enter()
          .append('text')
          .attr('class', 'center')
          .merge(centerText)
          .text((dd: any) => {
            return dd.data.label + ': ' + this.cd.valueLabel(dd.value);
          });
      })
      .on('mouseout', (d: any) => {
        // d3.select(this.pieContainer).attr('d', (dd) => {
        //  return arc(dd);
        // });
        // remove the center text
        this.pieContainer
          .selectAll('.center')
          .text('');
      })
      /**/
      .transition()
      .ease(d3.easeCircle)
      .duration(2000)
      .attr('d', arc) // must set x/y
      .attrTween('d', (d: any) => { // tweenArcs
        // console.log('tweenArcs', d, this);
        const interpolator = this.getArcInterpolator(this, d);
        return (t: any) => {
          return arc(interpolator(t));
        };
      })
    ;
  } // updateArcElements

  /**
   * Update Labels
   * @param arcs pie data
   * @param arcLabels arc label dimensions
   */
  private updateLabels(arcs: d3.PieArcDatum<{ valueOf(): number; }>[],
                       arcLabels: d3.Arc<any, d3.DefaultArcObject>): void {
    const textElements = this.pieContainer
      .selectAll('.labels')
      .data(arcs);
    textElements.enter()
      .append('text')
      .attr('class', 'labels')
      .merge(textElements)
      .text((d: d3.PieArcDatum<PieChartArc>) => {
        // d: { data: PieChartArt, endAngle, index, padAngle, startAngle, value}
        // console.log('text d', d);
        return d.data.label + ': ' + this.cd.valueLabel(d.value);
      })
      .attr('dy', '0.35em')

      .transition()
      .ease(d3.easeCircle)
      .duration(2000)
      .attrTween('transform', (d: any) => { // tweenLabels
        const interpolator = this.getArcInterpolator(this, d);
        return (t: any) => {
          const p = arcLabels.centroid(interpolator(t));
          const xy = p;
          xy[0] = xy[0] * 1.2;
          // xy[0] = (xy[0] < 0 ? xy[0] -20 : xy[0] + 20);
          return 'translate(' + xy + ')';
        };
      })
      .styleTween('text-anchor', (d: any) => { // tweenAnchor
        const interpolator = this.getArcInterpolator(this, d);
        return (t: any) => {
          const x = arcLabels.centroid(interpolator(t))[0];
          return (x > 0) ? 'start' : 'end';
        };
      });

  } // updateLabels

  /**
   * Update Lines
   * @param arcs pie data
   * @param arc base arc dimensions
   * @param arcLabels arc label dimensions
   */
  private updateLines(arcs: d3.PieArcDatum<{ valueOf(): number; }>[],
                      arc: d3.Arc<any, d3.DefaultArcObject>,
                      arcLabels: d3.Arc<any, d3.DefaultArcObject>): void {
    // -- lines --
    const lineElements = this.pieContainer
      .selectAll('.lines')
      .data(arcs);
    lineElements.enter()
      .append('path')
      .attr('class', 'lines')

      .merge(lineElements)

      .transition()
      .ease(d3.easeCircle)
      .duration(2000)
      .attrTween('d', (d: any) => { // tweenLines
        const interpolator = this.getArcInterpolator(this, d);
        const lineGen = d3.line();
        return (t: any) => {
          const dInt = interpolator(t);
          const start: [number, number] = arc.centroid(dInt);
          const xy: [number, number] = arcLabels.centroid(dInt);
          const textXy: [number, number] = [xy[0], xy[1]];
          // a little bit of extra space
          textXy[0] = textXy[0] * 1.15;
          // console.log('line', start, xy, textXy);
          return lineGen([start, xy, textXy]);
        };
      })
    ;
  } // updateLines

  /**
   * Update Pie Chart
   */
  private updatePie(): void {

    // https://github.com/josdirksen/d3dv/blob/master/src/chapter-02/js/D02-01.js

    const arc: d3.Arc<any, d3.DefaultArcObject> = d3.arc()
      .outerRadius(this.contentHeight / 2 * 0.6)
      .innerRadius(this.contentHeight / 2 * 0.3);

    const arcBg: d3.Arc<any, d3.DefaultArcObject> = d3.arc()
      .outerRadius(this.contentHeight / 2 * 0.6)
      .innerRadius(this.contentHeight / 2 * 0.3)
      .startAngle(0)
      .endAngle(2 * Math.PI);

    const arcPopup: d3.Arc<any, d3.DefaultArcObject> = d3.arc()
      .outerRadius(this.contentHeight / 2 * 0.65)
      .innerRadius(this.contentHeight / 2 * 0.3);

    const arcLabels: d3.Arc<any, d3.DefaultArcObject> = d3.arc()
      .outerRadius(this.contentHeight / 2 * 0.7)
      .innerRadius(this.contentHeight / 2 * 0.7);

    // -- background --
    this.pieContainer.append('path')
      .attr('class', 'background')
      .attr('d', arcBg);

    const pie = d3.pie()
      .sort(null)
      .padAngle(0.04)
      .value((d, i, a) => { // { value, index, PieChartArc[] }
        // console.log('pie d=' + d + ' i=' + i, a);
        return +d.valueOf();
      });
    // this.log.log('initSvg pie', pie);

    const arcs: d3.PieArcDatum<{ valueOf(): number; }>[] = pie(this.cd.data);
    // { data: PieChartArc, index, value, startAngle, endAngle, value }[]
    // this.log.log('initSvg arcs', arcs);

    this.updateArcElements(arcs, arc, arcPopup);
    this.updateLabels(arcs, arcLabels);
    this.updateLines(arcs, arc, arcLabels);

    // Total
    const totalText = this.g
      .selectAll<SVGTextElement, any>('.total')
      .data([ this.cd.totalLabel + this.cd.valueLabel(this.cd.totalValue) ]);
    totalText.enter()
      .append('text')
      .attr('class', 'total')
      .merge(totalText)
      .text((dd) => {
        return dd;
      });
  } // updatePie

} // PieChartComponent
