import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';

import {AccortoService, appStatus, DataRecord, FormComponent, Logger, ModelUtil, UiTab} from 'accorto';
import {TEItemUtil} from '../model/t-e-item-util';
import {TrackItemService} from '../track-item/track-item.service';
import {CResponseTrack} from '../model/c-response-track';
import {Store} from '@ngrx/store';
import {TrackState} from '../track-item/track-item.reducer';

/**
 * Time Entry
 *  /ui/TEItem/
 *  parameter: ?id=
 */
@Component({
  selector: 't4d-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeComponent
  implements OnInit, OnDestroy {

  ui: UiTab = new UiTab();
  record: DataRecord = new DataRecord();

  /** Child Form */
  @ViewChild(FormComponent, {static: false}) theForm?: FormComponent;

  /** busy */
  busy: boolean = true;
  /** messages */
  message?: string;
  error?: string;

  private log: Logger = new Logger('Time');
  private subscriptions: Subscription[] = [];
  private originalUi: UiTab = new UiTab();

  /**
   * Time
   */
  constructor(private route: ActivatedRoute,
              private store: Store<TrackState>,
              private conf: AccortoService,
              private service: TrackItemService) {
  }

  get showDelete(): boolean {
    return this.record.id != null && this.record.id.length > 0;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // ui
    this.subscriptions.push(this.route.data.subscribe((data => {
      const ui = data.uiTab;
      // this.log.debug('uiTab', ui)();
      if (ui) {
        this.originalUi = ui;
        this.initUi();
        this.record = ModelUtil.newDataRecord(ui);
        this.busy = false;
        this.store.dispatch(appStatus({status: 'time'}));
      }
    })));

    // parameter
    this.subscriptions.push(this.route.queryParams.subscribe((params: Params) => {
      const id = params.id;
      if (id) {
        this.log.debug('ngInit params', id)();
        this.service.queryId(id).subscribe((response: CResponseTrack) => {
          this.message = response.message;
          this.error = response.error;
          if (response.records && response.records.length > 0) {
            this.setRecord(response.records[ 0 ]);
          } else {
            this.error = 'Record Not found: ' + id;
          }
        });
      }
    }));
  } // ngInit

  /**
   * Delete
   */
  onDelete(): void {
    if (this.record.id) {
      this.service.delete(undefined, [this.record], undefined)
        .subscribe((response: CResponseTrack) => {
          this.message = response.message;
          this.error = response.error;
          if (!this.error) {
            this.onNew();
            this.message = response.message;
          }
        });
    }
  } // onDelete

  /**
   * New Record
   */
  onNew(): void {
    this.record = ModelUtil.newDataRecord(this.ui);
    this.message = undefined;
    this.error = undefined;
    this.log.info('onNew', this.record)();
  } // onNew

  /**
   * Save Record - called from Form/Record
   * @param record record to be saved
   */
  saveRecord(record: DataRecord): void {
    this.log.info('saveRecord', record)();
    this.busy = true;
    this.service.saveRecord(record, undefined)
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        if (response.records && response.records.length > 0) {
          this.setRecord(response.records[0]);
          this.log.debug('saveRecord.result', this.record)();
          //  this.router.navigate([ '/ui', this.ui.name, this.record.id ]);
        }
        this.busy = false;
      });
  } // saveRecord

  statusUpdate(msg: string): void {
    this.log.info('statusUpdate', msg)();
    this.message = msg;
  }

  /**
   * Create UI
   */
  private initUi(): void {
    if (this.originalUi) {
      this.ui = TEItemUtil.cloneUi(this.originalUi, 'time',
        true, this.conf.session?.settings);
      this.log.debug('initUi', this.ui)();
    }
  } // initUi

  // Single Day
  private isSingleDay(record: DataRecord): boolean {
    const hours = this.toNum(record.valueMap.hours);
    const x1 = this.toNum(record.valueMap.x1);
    let remaining = hours;
    let offset = 0;
    if (x1 !== 0) {
      if (x1 === remaining) {
        remaining -= x1;
        record.changeMap.x1 = '0';
      } else {
        return false;
      }
    }
    const x2 = this.toNum(record.valueMap.x2);
    if (x2 !== 0) {
      if (x2 === remaining) {
        remaining -= x2;
        record.changeMap.x2 = '0';
        offset = 1;
      } else {
        return false;
      }
    }
    const x3 = this.toNum(record.valueMap.x3);
    if (x3 !== 0) {
      if (x3 === remaining) {
        remaining -= x3;
        record.changeMap.x3 = '0';
        offset = 2;
      } else {
        return false;
      }
    }
    const x4 = this.toNum(record.valueMap.x4);
    if (x4 !== 0) {
      if (x4 === remaining) {
        remaining -= x4;
        record.changeMap.x4 = '0';
        offset = 3;
      } else {
        return false;
      }
    }
    const x5 = this.toNum(record.valueMap.x5);
    if (x5 !== 0) {
      if (x5 === remaining) {
        remaining -= x5;
        record.changeMap.x5 = '0';
        offset = 4;
      } else {
        return false;
      }
    }
    const x6 = this.toNum(record.valueMap.x6);
    if (x6 !== 0) {
      if (x6 === remaining) {
        remaining -= x6;
        record.changeMap.x6 = '0';
        offset = 5;
      } else {
        return false;
      }
    }
    const x7 = this.toNum(record.valueMap.x7);
    if (x7 !== 0) {
      if (x7 === remaining) {
        remaining -= x7;
        record.changeMap.x7 = '0';
        offset = 6;
      } else {
        return false;
      }
    }
    if (hours === remaining) {
      return true; // single day
    }
    if (remaining === 0) {
      if (offset !== 0) {
        const teDate = Number(record.valueMap.teDate);
        record.changeMap.teDate = String(teDate + (offset * 60000 * 60 * 24));
      }
      return true; // made single day
    }
    return false;
  } // isSingleDay

  // set time record
  private setRecord(record: DataRecord): void {
    if (record.changeMap) {
      record.changeMap = {}; // remove normalization
    }
    if (!this.isSingleDay(record)) {
      record.changeMap = {}; // changes
      record.isReadOnly = true;
      this.message = 'Item is for multiple days - please use Timesheet to edit';
    }
    this.record = record;
  }

  private toNum(value: any): number {
    if (value) {
      return Number(value);
    }
    return 0;
  }
} // TimeComponent
