import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AccortoCUtil, AccortoService, appStatus, DataRecord, Logger, ModelUtil, Preference, UiTab} from 'accorto';
import {TrackState} from '../track-item/track-item.reducer';
import {selectTrackRecords, selectTrackStartTime} from '../track-item/track-item.selectors';
import {TEItemUtil} from '../model/t-e-item-util';
import {TrackItemService} from '../track-item/track-item.service';
import {CResponseTrack} from '../model/c-response-track';
import {Track4dType} from '../model/c-request-track';
import {trackItemResponse} from '../track-item/track-item.actions';
import {TEItemD} from '../model/t-e-item-i';

/**
 * Timesheet
 * initial date from query parameter
 *  http://localhost:4200/timesheet?date=2019-05-26
 * handled in TrackItemService
 */
@Component({
  selector: 't4d-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: [ './timesheet.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class TimesheetComponent implements OnInit, OnDestroy {

  /** Show weekends */
  showWeekends: boolean = false;
  askWeekends: boolean = true;
  /** Show weekends */
  showDescriptions: boolean = false;
  askDescriptions: boolean = true;
  /** Overwrite settings */
  settings: { [key: string]: string | undefined | null } = {};

  /** ui definition */
  ui: UiTab = new UiTab();
  /** timesheet record list */
  records: DataRecord[] = [];

  /** Busy spinner */
  busy: boolean = false;
  message?: string;
  error?: string;

  /** all records */
  private allRecords: DataRecord[] = [];

  private log: Logger = new Logger('Timesheet');
  private subscriptions: Subscription[] = [];
  private startDate: Date = new Date();
  private changes: string = '';
  private originalUi: UiTab = new UiTab();

  /**
   * Timesheet
   */
  constructor(private route: ActivatedRoute,
              private store: Store<TrackState>,
              private router: Router,
              private conf: AccortoService,
              private service: TrackItemService) {
    this.showWeekends = Preference.prefShowWeekends.isValue;
  }

  doRefresh(): void {
    this.log.info('doRefresh', this.startDate)();
    this.busy = true;
    this.message = 'refreshing ...';
    this.error = undefined;
    this.service.query(this.startDate.getTime(), 'refresh')
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        this.log.info('doRefresh.response ' + this.message)();
        this.store.dispatch(trackItemResponse({response}));
      });
  }

  /**
   * Save Records - called from DataTable with changed records
   * @param records changed/new records
   */
  doSaveRecords(records: DataRecord[]): void {
    this.busy = true;
    this.message = 'saving ...';
    this.error = undefined;
    const startTime = this.startDate.getTime();
    records.forEach((record: DataRecord) => {
      const teDate = record.value(TEItemD.teDate.n);
      if (teDate === undefined) {
        record.setValue(TEItemD.teDate.n, String(startTime)); // set date
      }
    });
    this.service.save(startTime, records, Track4dType.QUERY)
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        this.store.dispatch(trackItemResponse({ response }));
      });
  } // saveRecords

  /**
   * StartDate Changed (starting week-select)
   */
  doStartDate(utcDate: Date): void {
    this.busy = true;
    this.message = undefined;
    this.error = undefined;
  }

  /**
   * Submit Timesheet
   */
  doSubmit(): void {
    this.log.info('doSubmit', this.startDate)();
    this.busy = true;
    this.message = 'submitting ...';
    this.error = undefined;
    this.service.submitTimesheet(this.startDate.getTime())
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        this.store.dispatch(trackItemResponse({response}));
      });
  } // doSubmit

  initUiExec(): void {
    if (this.originalUi && this.startDate) {
      this.ui = TEItemUtil.cloneUi(this.originalUi, 'ts',
        true, this.conf.session?.settings,
        this.showWeekends, this.startDate);
      const dd = this.startDate.toISOString().replace('T00:00:00.000Z', '');
      this.log.info('initUi_' + this.changes, 'start=' + this.startDate.toISOString() + ' weekends=' + this.showWeekends, this.ui)();

      // update query parameter (used in initial query)
      const u = this.router.createUrlTree(['/timesheet'], {queryParams: {date: dd}});
      this.router.navigateByUrl(u, {replaceUrl: true});

      // records
      if (this.changes.includes('r') && this.changes.includes('d')) {
        this.initUiProcessRecords();
        this.log.debug('initUi_' + this.changes, 'records ' + this.allRecords.length + ' to ' + this.records.length, this.records)();
        this.message = (this.message ? this.message + '; ' : '') + 'time records found: ' + this.records.length;
        // add new record
        this.records.push(ModelUtil.newDataRecord(this.ui));
      }
    } // originalUi+startDate
    this.busy = false;
  } // initUiExec

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  } // ngOnDestroy

  ngOnInit(): void {
    // ui
    this.subscriptions.push(this.route.data
      .subscribe((data => {
        const ui = data.uiTab;
        this.log.debug('ngOnInit uiTab', ui)();
        if (ui) {
          this.originalUi = ui;
          this.initUi('u');
        }
      })));
    // records - WeekSelect -> TrackItemService.query
    this.subscriptions.push(this.store.pipe(select(selectTrackRecords))
      .subscribe((records: DataRecord[]) => {
        this.log.debug('ngOnInit records', records)();
        this.allRecords = [];
        if (records) {
          for (const dr of records) { // records are frozen
            const record = AccortoCUtil.createDataRecord(dr);
            this.allRecords.push(record);
          }
        }
        this.initUi('r');
      }));
    // start date
    this.subscriptions.push(this.store.pipe(select(selectTrackStartTime))
      .subscribe((date) => {
        this.log.debug('ngOnInit date', date)();
        if (date) {
          //  this.busy = true;
          this.startDate = date;
          this.initUi('d');
        }
      }));
    this.store.dispatch(appStatus({ status: 'timesheet' }));

    // settings - weekends - descriptions
    this.settings = Object.assign({}, this.conf.session?.settings);
    const showWeekends = this.settings.showWeekends;
    if (showWeekends === 'yes') {
      this.askWeekends = false;
      this.showDescriptions = true;
    } else if (showWeekends === 'no') {
      this.askWeekends = false;
      this.showDescriptions = false;
    }
    const hoursDescriptions = this.settings.hoursDescriptions;
    if (hoursDescriptions === 'yes' || hoursDescriptions === 'required') {
      this.askDescriptions = false;
      this.showDescriptions = true;
    } else if (hoursDescriptions === 'no') {
      this.askDescriptions = false;
      this.showDescriptions = false;
    }
    this.log.debug('ngOnInit', 'showWeekends=' + showWeekends + ', hoursDescriptions=' + hoursDescriptions)();
  } // ngOnInit

  /**
   * Toggle Show Descriptions
   * @param event mouse event
   */
  onDescriptionsClick(event: MouseEvent): void {
    const source = event.target as HTMLInputElement;
    this.busy = true;
    setTimeout(() => {
      this.showDescriptions = source.checked;
      this.settings = Object.assign({}, this.settings, {
        hoursDescriptions: this.showDescriptions ? 'yes' : 'no'
      });
      this.log.info('onDescriptionsClick ' + this.showDescriptions, this.settings)();
      this.busy = false;
    }, 1);
  } // onWeekendClick

  /**
   * Toggle Show Weekend
   * @param event mouse event
   */
  onWeekendClick(event: MouseEvent): void {
    const source = event.target as HTMLInputElement;
    this.showWeekends = source.checked;
    this.log.info('onWeekendClick ' + this.showWeekends)();
    this.initUi('w');
  } // onWeekendClick

  /**
   * Init Ui if there is a ui and start date
   * @param changeType w=weekends d=date u=ui r=records
   */
  private initUi(changeType: string): void {
    this.busy = true;
    this.changes += changeType;
    setTimeout(() => this.initUiExec(), 1);
  } // initUi

  private initUiProcessRecords(): void {
    this.records = this.allRecords
      .filter((dr: DataRecord) => { // items with hours
        const hours = dr.valueMap.hours;
        return hours && hours.length > 0 && Number(hours); // != 0
      })
      .map((dr: DataRecord) => { // set start date
        const teDateString = dr.value(TEItemD.teDate.n);
        const teDateTime = Number(teDateString);
        if (teDateTime !== this.startDate.getTime()) {
          return TEItemUtil.toStartDate(dr, this.startDate);
        }
        return dr;
      });
    this.changes = '';
  } // initUiProcessRecords

} // TimesheetComponent
