import { CRequestTrack } from './c-request-track';
import { CResponseTrack } from './c-response-track';
import { PerDiemItem } from './per-diem-item';
import { Track4dUtil } from './track4d-util';
import { AccortoCUtil } from 'accorto';

/* GenTsClass utility: Track4dCUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Client Generated Utility for com.track4d.domain
 */
export class Track4dCUtil extends Track4dUtil {


  /**
   * Convert json to CRequestTrack
   * @return populated CRequestTrack
   * @param json json info
   */
  static createCRequestTrack(json: any): CRequestTrack {
    const entity: CRequestTrack = new CRequestTrack();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'endTimeMs') {
        entity.endTimeMs = value;
      } else if (key === 'isEditable') {
        entity.isEditable = value;
      } else if (key === 'orderBys') {
        entity.orderBys = [];
        for (const vv of value) {
          entity.orderBys.push(vv);
        }
      } else if (key === 'perDiemItems') {
        entity.perDiemItems = [];
        for (const vv of value) {
          entity.perDiemItems.push(this.createPerDiemItem(vv));
        }
      } else if (key === 'queryId') {
        entity.queryId = value;
      } else if (key === 'records') {
        entity.records = [];
        for (const vv of value) {
          entity.records.push(AccortoCUtil.createDataRecord(vv));
        }
      } else if (key === 'startTimeMs') {
        entity.startTimeMs = value;
      } else if (key === 'type') {
        entity.type = value;
      } else if (key === 'typeReQuery') {
        entity.typeReQuery = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestTrack


  /**
   * Convert json to CResponseTrack
   * @return populated CResponseTrack
   * @param json json info
   */
  static createCResponseTrack(json: any): CResponseTrack {
    const entity: CResponseTrack = new CResponseTrack();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'perDiemItems') {
        entity.perDiemItems = [];
        for (const vv of value) {
          entity.perDiemItems.push(this.createPerDiemItem(vv));
        }
      } else if (key === 'primarySort') {
        entity.primarySort = value;
      } else if (key === 'records') {
        entity.records = [];
        for (const vv of value) {
          entity.records.push(AccortoCUtil.createDataRecord(vv));
        }
      } else if (key === 'startTimeMs') {
        entity.startTimeMs = value;
      } else if (key === 'endTimeMs') {
        entity.endTimeMs = value;
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseTrack


  /**
   * Convert json to PerDiemItem
   * @return populated PerDiemItem
   * @param json json info
   */
  static createPerDiemItem(json: any): PerDiemItem {
    const entity: PerDiemItem = new PerDiemItem();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'dateMs') {
        entity.dateMs = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'destination') {
        entity.destination = value;
      } else if (key === 'destinationDetail') {
        entity.destinationDetail = value;
      } else if (key === 'destinationOptions') {
        entity.destinationOptions = [];
        for (const vv of value) {
          entity.destinationOptions.push(vv);
        }
      } else if (key === 'endDateMs') {
        entity.endDateMs = value;
      } else if (key === 'isBreakfast') {
        entity.isBreakfast = value;
      } else if (key === 'isDinner') {
        entity.isDinner = value;
      } else if (key === 'isIncidental') {
        entity.isIncidental = value;
      } else if (key === 'isLodging') {
        entity.isLodging = value;
      } else if (key === 'isLunch') {
        entity.isLunch = value;
      } else if (key === 'isMeals') {
        entity.isMeals = value;
      } else if (key === 'isMeals2') {
        entity.isMeals2 = value;
      } else if (key === 'rateBreakfast') {
        entity.rateBreakfast = value;
      } else if (key === 'rateDinner') {
        entity.rateDinner = value;
      } else if (key === 'rateIncidental') {
        entity.rateIncidental = value;
      } else if (key === 'rateLodging') {
        entity.rateLodging = value;
      } else if (key === 'rateLunch') {
        entity.rateLunch = value;
      } else if (key === 'rateMeals') {
        entity.rateMeals = value;
      } else if (key === 'rateMeals2') {
        entity.rateMeals2 = value;
      } else if (key === 'type') {
        entity.type = value;
      }
    } // for loop
    return entity;
  } // createPerDiemItem

} // Track4dCUtil
