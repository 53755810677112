import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {AccortoService, appStatus, DataRecord, FkCache, FkUtil, Logger, selectFkCache, UiTab} from 'accorto';
import {TrackState} from '../track-item/track-item.reducer';
import {selectTrackRecords, selectTrackStartTime} from '../track-item/track-item.selectors';
import {BarChartStackedData} from '../d3/bar-chart-stacked/bar-chart-stacked.data';
import {PieChartData} from '../d3/pie-chart/pie-chart.data';
import {TEItemUtil} from '../model/t-e-item-util';
import {trackItemRequest} from '../track-item/track-item.actions';

/**
 * Track4D Dashboard
 */
@Component({
  selector: 't4d-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {

  startDate: Date = new Date();
  message: string = '';

  ui: UiTab = new UiTab();

  projectFkMap?: Map<string, string>;
  activityFkMap?: Map<string, string>;
  finAccountFkMap?: Map<string, string>;
  records?: DataRecord[];

  projectData: BarChartStackedData = new BarChartStackedData();
  activityData: BarChartStackedData = new BarChartStackedData();
  expenseData: PieChartData = new PieChartData();


  private log: Logger = new Logger('Dashboard');
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private store: Store<TrackState>,
              private conf: AccortoService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // ui
    this.subscriptions.push(this.route.data.subscribe((data => {
      // this.log.debug('ngOnInit.ui', data.uiTab)();
      this.initUi(data.uiTab);
    })));

    // records
    this.subscriptions.push(this.store.pipe(select(selectTrackRecords)).subscribe((records) => {
      this.log.debug('ngOnInit.records', records)();
      this.updateDashboard(records);
    }));

    // date
    this.subscriptions.push(this.store.pipe(select(selectTrackStartTime)).subscribe((date) => {
      if (date) {
        this.startDate = date;
        this.log.debug('ngOnInit.date', date.toISOString())();
      }
    }));

    // Fks
    this.subscriptions.push(this.store.pipe(select(selectFkCache('Project'))).subscribe((fkCache: FkCache) => {
      if (fkCache) {
        // this.log.debug('ngOnInit.fk Table', fkCache)();
        this.projectFkMap = fkCache.idLabelMap();
        this.updateDashboard2();
      }
    }));
    this.subscriptions.push(this.store.pipe(select(selectFkCache('ActivityType'))).subscribe((fkCache) => {
      if (fkCache) {
        // this.log.debug('ngOnInit.fk ActivityType', fkCache)();
        this.activityFkMap = fkCache.idLabelMap();
        this.updateDashboard2();
      }
    }));
    this.subscriptions.push(this.store.pipe(select(selectFkCache('FinancialAccount'))).subscribe((fkCache) => {
      if (fkCache) {
        // this.log.debug('ngOnInit.fk FinAccount', fkCache)();
        this.finAccountFkMap = fkCache.idLabelMap();
        this.updateDashboard2();
      }
    }));
  } // ngOnInit

  /**
   * Refresh - calls updateDashboard()
   */
  onRefresh(): void {
    this.log.debug('onRefresh')();
    this.message = 'refreshing ...';
    this.store.dispatch(trackItemRequest({ startTimeMs: this.startDate.getTime() }));
  }

  /**
   * @param ui init ui
   */
  private initUi(ui: UiTab): void {
    if (ui && this.ui.gridFieldList.length === 0) {
      this.ui = TEItemUtil.cloneUi(ui, 'et',
        true, this.conf.session?.settings, undefined, undefined);
      // this.log.debug('initUi', this.ui)();
    }
  } // updateUi

  /**
   * Update Dashboard
   * @param records the weekly records
   */
  private updateDashboard(records: DataRecord[]): void {
    if (records && records.length > 0) {
      if (records.length === 1) {
        this.message = 'One records';
      } else {
        this.message = records.length + ' records';
      }
    } else {
      this.message = 'No records for week';
    }
    FkUtil.preloadGridFKs(this.store, this.ui, records ? records : []);
    this.records = records;
    //
    this.store.dispatch(appStatus({ status: 'dashboard' }));
    this.updateDashboard2();
  } // updateDashboard

  /**
   * Update Dashboard data (fk)
   */
  private updateDashboard2(): void {
    /*
    const info = (this.projectFkMap ? 'pj ' : 'noPj ')
      + (this.activityFkMap ? 'at ' : ' noAt ')
      + (this.finAccountFkMap ? 'fa ' : ' noFa ')
      + (this.records ? 'records=' + this.records.length : 'noRecords');
    this.log.debug('updateDashboard', info)();
    */
    if (this.records
      && this.projectFkMap) {
      this.projectData = new BarChartStackedData();
      this.projectData.label = 'Hours per Project';
      this.projectData.subLabel = 'selected week';
      this.projectData.dimensionLabelSuffix = 'h';
      this.projectData.valueFormat = '.1f';

      this.activityData = new BarChartStackedData();
      this.activityData.label = 'Hours per Activity';
      this.activityData.subLabel = 'selected week';
      this.activityData.dimensionLabelSuffix = 'h';
      this.activityData.valueFormat = '.1f';

      this.expenseData = new PieChartData();
      this.expenseData.label = 'Expenses';
      this.expenseData.subLabel = 'selected week';
      this.expenseData.dimensionLabelPrefix = '$'; // TODO

      TEItemUtil.update(this.records,
        this.projectData, this.projectFkMap,
        this.activityData, this.activityFkMap,
        this.expenseData, this.finAccountFkMap);
    }
  } // updateDashboard2

} // DashboardComponent
